/* App.css */

body {
  font-family: 'Roboto', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.header-with-line {
  border-bottom: 2px solid gray; /* Gray horizontal line */
  padding-bottom: 10px; /* Space between the text and the line */
}

.upper-banner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #282F3D;
  color: white;
  padding: 0px;
  justify-content: flex-start; 
  align-items: center;
  width: 100%;
  height: 70px;
}

.banner-image {
  height: 50px; /* Adjust based on your desired image size */
  margin-left: 30px; /* Space between the image and the next element */
  vertical-align: middle;
  padding-top: 10px;
}

.banner-top-text {
  margin-left: 20px;
  font-size: 25px;
  margin-top: 0px;
  align-items: center;     /* Centers the content vertically */
  padding-top: 0px;
}

.bottom-banner {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #282F3D;
  color: white; /* Adjust text color if needed */
  text-align: center;
  padding: 10px 0; /* Adjust padding as needed */
  z-index: 1000;
}

.content {
  margin-left: 400px; /* Adjust based on menu width */
  padding-top: 20px;
  padding-right: 100px;
  padding-bottom: 40px;
}

.menu-item {
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  color: inherit; /* This ensures links use the same text color as normal text */
}

.menu-item:hover {
  background-color: #ddd;
}

.iframe-container {
  margin-top: 50px; /* Adjust the value as needed */
  width: 80%;
  height: 600px
}

.video-header {
  margin-top: 50px;
  font-weight: 700;
}

.logout-link {
  float: right;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  background-color: #4CAF50;
  height: 100%;
  cursor: pointer;
  font-weight: bold;
}

.logout-link:hover {
  text-decoration: underline; /* Hover effect */
}

.user-logged {
  float: right;
  padding-right: 20px;
  padding-top: 6px;
  font-weight: bold;
}