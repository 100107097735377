/* Menu.css */

.menu-panel {
  width: 300px;
  background-color: #282F3D; /* AWS-like dark blue */
  color: white;
  height: calc(100vh - 60px); /* Adjust height based on banner height */
  position: fixed;
  left: 0;
  top: 70px; /* Adjust top position based on banner height */
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 30px;
}

.menu-section {
  border-bottom: 1px solid #37475a; /* Subtle separator */
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.menu-item {
  cursor: pointer;
  padding: 10px 15px;
  margin-top: 15px;
}

.menu-item, .active-menu-item {
  color: white;
}

.menu-item:hover, .active-menu-item {
  background-color: #282F3D; /* Interactive color change */
  color: #FF9900;
  padding-left: 11px; /* Adjust padding to align text after border */
  text-decoration: none;
}

.sub-menu {
  padding-left: 30px;
}

.logout {
  background-color: #4CAF50;
  color: white;
  padding: 7px 20px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  text-align: center;
}
